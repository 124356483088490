/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// TODO remove this in future in favour of importing directly into coffeescript files
window.jQuery = jQuery;
window.$ = $;

// Rails support
import Rails from "@rails/ujs";
Rails.start();

// Notify Sentry of an JavaScript errors
import * as Sentry from "@sentry/react";
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://33c02802b1b5457c92ea68dfc5fdc794@sentry.epigenesys.org.uk/53",
  });
}

import "bootstrap/js/modal";
require("data-confirm-modal");
dataConfirmModal.setDefaults({
  title: "Are you sure?",
  commit: "Yes",
  commitClass: "btn-primary",
  cancel: "No",
  cancelClass: "btn-default cancel",
  focus: "cancel",
  modalClass: "modal-confirm-dialog",
  fade: process.env.RAILS_ENV !== "test",
});

// jquery-ui import
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/sortable";

import "select2";
import "../coffee/identifier";
// import 'select2/dist/css/select2.css';

// TODO: Use FlashMessage from epi-js
import { AjaxModal, VisibilityMap, OptionFilter } from "@epigenesys/epi-js";

// Support component names relative to the 'pages' directory
var pagesContext = require.context("pages", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(pagesContext);

// Study counter helpers
window.COMPONENT_COUNT_INCREMENT = "incrementCount";
window.COMPONENT_COUNT_DECREMENT = "decrementCount";
window.COMPONENT_COUNT_UPDATE = "updateCount";
$(() => {
  $(".study-components-count")
    .on(window.COMPONENT_COUNT_INCREMENT, (e, count = 1) => {
      e.target.innerHTML = parseInt(e.target.innerHTML) + count;
    })
    .on(window.COMPONENT_COUNT_DECREMENT, (e, count = 1) => {
      e.target.innerHTML = parseInt(e.target.innerHTML) - count;
    })
    .on(window.COMPONENT_COUNT_UPDATE, (e, value) => {
      e.target.innerHTML = value;
    });
});
window.findComponentCounter = (identifier) => {
  return $(
    `.study-components-count[data-study-components-count-for="${identifier}"]`
  );
};

// Stimulus
import "controllers";

// Scripts
import "services/visibility_map";
import Select2Helper from "services/select2_helper";
Select2Helper.start();
