export default class {
  static start() {
    $.fn.select2.defaults.set("theme", "bootstrap");
    this.initialiseSelect2($(document).find(".select2"));
    this.addEventListeners();
  }

  static addEventListeners() {
    $(document).on("ajax-modal-show", () => {
      this.initialiseForAjaxModal();
    });
    $(document).on("select2-init", ".select2", (e) => {
      this.initialiseSelect2(e.target);
    });
    $("#modalWindow").on("after-save-reset", () => {
      this.initialiseSelect2($("#modalWindow select"));
    });
    $(document).on("cocoon:after-insert", (e) => {
      this.initialiseForCocoonItem(e.target);
    });
  }

  static initialiseForAjaxModal() {
    // moved here due to bug when init-select2 causes 2 boxes to show
    if ($("#modalWindow form").length > 0) {
      $("#modalWindow").data("original-content", $("#modalWindow form").html());
    }

    this.initialiseSelect2("#modalWindow .select2");
  }

  static initialiseForCocoonItem(item) {
    this.initialiseSelect2($(item).find(".select2"));
  }

  static initialiseSelect2(elements, options = {}) {
    $(elements).select2({
      allowClear() {
        !!$(this).data("allow-clear");
      },
      ...options,
    });
  }
}
