import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="collapse-all"
export default class extends Controller {
  static targets = ["collapsible"];

  connect() {}

  expandAll() {
    this.collapsibleTargets.forEach((target) => {
      window.$(target).collapse("show");
    });
  }

  collapseAll() {
    this.collapsibleTargets.forEach((target) => {
      window.$(target).collapse("hide");
    });
  }
}
