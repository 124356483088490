import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    focusSelector: String,
  };

  connect() {
    $(this.element).on("cocoon:after-insert", (e) =>
      this.afterInsertHandler(e.target)
    );
  }

  afterInsertHandler(item) {
    if (this.hasFocusSelectorValue) {
      $(item).find(this.focusSelectorValue).trigger("focus");
    }
  }
}
