import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["updateListen"];

  initialize() {
    $(document).on("ajaxComplete", () => this.initialisePopovers(document));
  }

  connect() {
    this.initialisePopovers(this.element);
  }

  updateListenTargetConnected(element) {
    $(element).on("update", (e) => this.initialisePopovers(e.target));
  }

  initialisePopovers(elements) {
    $(elements).find('[data-toggle="popover"]').popover();
  }

  dismissPopovers(event) {
    // Source: https://stackoverflow.com/a/15670200
    if (!$(event.target).closest('[data-toggle="popover"]').length) {
      $('[data-toggle="popover"]').each(function () {
        $(this).popover("hide");

        // Source: https://stackoverflow.com/a/34320956
        $(this).data("bs.popover").inState = {
          click: false,
          hover: false,
          focus: false,
        };
      });
    }
  }
}
