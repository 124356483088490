import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="auto-populator"
export default class extends Controller {
  static targets = ["populatee"];

  connect() {}

  populate(e) {
    this.populateeTargets.forEach((target) => {
      if (target.value.length == 0) {
        target.value = e.target.value;
      }
    });
  }
}
