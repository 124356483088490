import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    items: { type: String, default: "> *" },
    handle: { type: String, default: ".sortable-handler" },
    updateSubRows: { type: Boolean, default: false },
    updateUrl: String,
    positionAttribute: String,
    successMessage: String,
    errorMessage: String,
  };

  connect() {
    $(this.element).sortable({
      items: this.itemsValue,
      placeholder: "ui-sortable-placeholder",
      forcePlaceholderSize: true,
      cursor: "move",
      tolerance: "pointer",
      start: (event, ui) => this.startHandler(event, ui),
      stop: (event, ui) => this.stopHandler(event, ui),
      update: (event, ui) => this.updateHandler(event, ui),
      axis: "y",
      handle: this.handleValue || false,
    });
  }

  startHandler() {
    $(this.element).addClass("sorting");
  }

  stopHandler() {
    $(this.element).removeClass("sorting");
  }

  updateHandler() {
    if (this.hasUpdateSubRowsValue) {
      this.updateSubRows();
    }
    if (this.hasPositionAttributeValue) {
      this.updatePositions();
    } else {
      this.sendUpdate();
    }
  }

  updatePositions() {
    $(this.element)
      .find(`input[name$="[${this.positionAttributeValue}]"]`)
      .each(function (index) {
        $(this).val(index + 1);
      });
  }

  sendUpdate() {
    $.ajax(this.updateUrlValue, {
      type: "PUT",
      data: $(this.element).sortable("serialize"),
      success: () => this.showSuccessMessage(),
      error: () => this.showErrorMessage(),
    });
  }

  showSuccessMessage() {
    if (this.successMessageValue) {
      $.flashAlert(this.successMessageValue, "alert-success");
    }
  }

  showErrorMessage() {
    if (this.errorMessageValue) {
      $.flashAlert(this.errorMessageValue, "alert-danger");
    }
  }

  updateSubRows() {
    $(this.element)
      .find(this.itemsValue)
      .each(function () {
        $(this).after($(`[data-sub-row-for="#${$(this).attr("id")}"]`));
      });
  }
}
